@font-face {
  src: url(../../fonts/jockeyOne/JockeyOne-Regular.ttf);
  font-family: joc;
}

:root {
  --nav-bg-color: transparent;
  --burger-menu-color: black;
  --nav-txt-color: white;
}

.navbar {
  position: fixed;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 8vh;
  background: var(--nav-bg-color);
  font-family: joc;
  margin: 0;
  transition: 0.5s;
}
.navMobActive {
  background: black;
  transition: 0.5s;
}
.navMobInactive {
  background: transparent;
}
.navBarLogo {
  filter: invert(0);
  height: 1.6em;
  padding: 2px;
}

.logo {
  display: inline-block;
  color: var(--nav-txt-color);
  font-family: joc;
  font-size: 1.5em;
}
.logo div {
  float: left;
  display: block;
  padding: 5px;
}

.navUl {
  display: flex;
  justify-content: space-around;
  width: 60%;
}
.navUl li {
  list-style: none;
}
.nav-links {
  text-decoration: none;
  color: #6d6d6d;
  font-size: 1.5em;
  transition: ease-in 0.3s;
}
.burger {
  display: none;
}
.burger div {
  width: 25px;
  height: 5px;
  border-radius: 1px;
  background: white;
  margin: 5px;
  transition: 0.3s;
}
@media screen and (max-width: 1808px) {
  .navUl {
    width: 60%;
  }

  .whiteBurgerBg {
    background-color: white;
  }
}
@media screen and (max-width: 1381px) {
  .navbar {
  }
  .navUl {
    overflow-y: hidden;
    position: absolute;
    right: 0px;
    height: 92vh;
    top: 5vh;
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    transform: translateX(100%);
    transition: transform 0.3s ease-in;
  }

  .burger {
    display: block;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .nav-active {
    transform: translateX(0%);
  }
  .animateIn {
    animation: navLinkFade 0.3s ease forwards 1s;
  }

  .whiteBurgerBg {
    background-color: white;
  }
}

@keyframes navLinksFade {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
  transition: 0.3s;
}
.toggle .line2 {
  opacity: 0;
  transition: 0.3s;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
  transition: 0.3s;
}

.navIdle {
  background: transparent;
}
.navScrolledBlack {
  background: black;
}
.navScrolledWhite {
  background: white;
}

.whiteNavText {
  color: white;
}
.blackNavText {
  color: black;
}
.navBlackLogo {
  filter: invert(1);
}
.blackBurger div {
  background-color: black;
}

.navContactUsButton {
  background-color: #7070fc; /* Green */
  border: none;
  color: white;
  font-weight: bold;
  padding: 0.2em 2em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  letter-spacing: 0.1em;
}
.navBttn {
  background-color: transparent;
  color: #7070fc;
  border: 2px solid #7070fc;
  border-radius: 300px;
}
.navContactUsButton:hover {
  background: #7070fc;
  color: white;
  display: inline-block;
}
.activateLink {
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-bottom-color: #6d6d6d;
}
.nav-links:hover {
  color: white;
  transition: ease-in 0.3s;
}

.blackNavLinks:hover {
  color: black;
  transition: ease-in 0.3s;
}
.nav-links {
  cursor: pointer;
}
