.sectorContainer {
  background-image: url("../../graphics/sector.jpg");
  height: auto;

  width: 100%;
  font-family: joc;
  color: white;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.822);
}
.sectorHead {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sectorContainer .sectorHead h1 {
  color: white;
  font-family: joc;
  font-size: 3em;
  margin-top: 2em;
}
.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contentSections {
  padding: 2em;
  max-width: 60em;
  font-size: 1.6em;
}
.contentSections div {
  color: #6d6d6d;
}

.contentSections h2 {
  color: white;
}
