.contactUsContainer {
  background-image: linear-gradient(to right, black, rgb(20, 20, 20));
  height: 100vh;
  margin: 0 auto;
  color: white;
  font-family: joc;
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  position: relative;
  z-index: 1;
}
.contactUsContents {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-around;
}
.contactUsForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.getInTouchContent {
  text-align: left;
  max-width: 20em;
  font-size: 1.4em;
}
.getInTouchContent h1 {
  font-size: 3em;
  letter-spacing: 3px;
}
.getInTouchContent div {
  color: #6d6d6d;
}
.contactUsForm {
  background: white;
  min-height: 400px;
  min-width: 300px;
  border-radius: 10px;
  padding: 40px;
  justify-content: space-around;
}
.contactUsForm textarea {
  height: 200px;
  width: 80%;

  border: #6d6d6d96;
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  padding: 10px;
  resize: none;
  margin-top: 20px;
}
.contactUsForm textarea:focus {
  outline: none;
}
.contactUsForm input {
  width: 80%;
  height: 40px;
  padding: 10px;
  margin: 10px;
  border: none;
  border-bottom: #6d6d6d96;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}
.contactUsForm input:focus {
  outline: none;
}
.contactUsSubmitButton {
  background: transparent;
  width: 80%;
  font-size: 30px;
  color: #7070fc;
  border-radius: 300px;
  border-style: solid;
  border-width: 2px;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  transition: ease-in 0.3s;
}
.contactUsSubmitButton:hover {
  background: #7070fc;
  color: white;
}
.scrollDownContactGif {
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
}
.scrollDownContactGif img {
  height: 8vh;
  padding: 2%;
}
@media screen and (max-width: 940px) {
  .contactUsContents {
    display: flex;
    flex-direction: column;
  }
  .contactUsContainer {
    height: auto;
  }
  .getInTouchContent {
    text-align: center;
    margin: 20px;
  }
  .contactUsForm {
    margin: 20px;
  }
  .scrollDownContactGif {
    width: 100%;
    bottom: 0;
    left: 0;
    position: relative;
  }
}
.contactUsError {
  color: red;
  height: 1.2em;
}

.modal {
  background-color: #1b1b1b;
  height: auto;
  width: auto;
  padding: 1.2em;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.2em;

  box-shadow: 0px 0px 10px #525252c4;
}
.modal div {
  margin: 20px;
}
.modal h1 {
  color: white;
}
.modal a {
  font-size: 2em;
  color: #7070fc;
  cursor: pointer;
  transition: ease-in 0.3s;
}
.modal a:hover {
  font-size: 3em;
}

.map {
  z-index: 0;
  width: 100%;
  height: 500px;
}
.map .marker {
  background-color: tomato;
}
.mapPopup {
  font-family: joc;
}
