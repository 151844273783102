.contentCard ul {
  display: flex;
  align-items: center;

  justify-content: space-around;
}
.contentCard ul li {
  list-style: none;
  list-style-position: inside;
  margin: 0;
  padding: 0;
  font-size: 30px;
}
.contentCard ul li img {
  height: 20rem;
  padding: 5px;
}
.contentCard {
  align-items: center;
  margin-top: 20px;
  background: transparent;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.123);
  max-width: 2000px;
  width: 100%;

  display: inline-block;
  margin: 0 auto;
  padding: 3px;

  transition: 0.3s ease-in;
}
.contentCard:hover {
  box-shadow: none;
}
.contentCardText {
  max-width: 600px;
}
.contentContainer {
  text-align: center;
  width: 100%;
}
.reverseContentCard ul {
  flex-direction: row-reverse;
}
.reverseContentCard {
  margin-top: 20px;
}
.contentContainer ul li {
  list-style: none;
}
.CardContainer {
  margin-top: 20px;
}
#cardText {
  color: #6d6d6d;
}
.reverse {
  flex-direction: row-reverse;
}
@media screen and (max-width: 972px) {
  .contentCard ul {
    flex-direction: column-reverse;
  }
  .reverse {
    flex-direction: column-reverse;
  }
  .contentCard ul li img {
    height: 10rem;
  }
}

#cardViewDivider {
  width: 100%;
  height: 2px;
  border: none;
  background-color: rgba(255, 255, 255, 0.13);
  border-radius: 300px;
  margin-bottom: 1em;
  padding: 0;
}
