.custButton {
  background-color: #7070fc; /* Green */
  border: none;
  color: white;
  padding: 8px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;

  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.custBttn {
  background-color: transparent;
  color: #7070fc;
  border: 2px solid #7070fc;
  border-radius: 300px;
}
.custButton:hover {
  background: #7070fc;
  color: white;
  display: inline-block;
}
