.secondScreen {
  background-image: url("../../graphics/services.jpg");
  height: auto;
  font-family: joc;
  width: 100%;
  font-family: joc;
  color: white;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.822);
}

.secondScreenHeads {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.secondScreenHeads h1 {
  color: white;
  font-family: joc;
  font-size: 3em;
  margin-top: 2em;
  padding: 0.1em;
}
.secondScreenHeads p {
  color: #6d6d6d;
  font-family: joc;
  font-size: 1.5em;
  padding: 0.1em;
}
.contentContainer {
  display: table;

  justify-content: center;
  align-items: center;
}
.contentContainer ul {
  padding: 0;
}
.contentContainer ul li {
  padding: 0.8em;
}
