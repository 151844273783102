@font-face {
  src: url(../../fonts/jockeyOne/JockeyOne-Regular.ttf);
  font-family: joc;
}
.logoCont {
  position: relative;
}
.logoImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#svgManeSLogo {
  height: 40vh;
}
#subtitle {
  color: #6d6d6d;
  font-size: 90px;
}
.scrollDownGif {
  width: 100%;
  margin-top: 15%;
}
.scrollDownGif img {
  height: 8vh;
}
.startPg {
  background-image: url("../../graphics/office-1209640_1920.jpg");
  height: 100vh;

  width: 100%;
  font-family: joc;
  color: white;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.822);
}
.BlackBg {
  background-image: linear-gradient(to right, #000000, #2e2e2e);
  height: 100vh;
  padding: 10px;
  width: 100%;
  font-family: joc;
  color: white;
}

.imageNtxtUl {
  display: flex;
  justify-content: space-around;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.imageNtxtUl li {
  list-style: none;
  font-family: joc;
  font-size: 1.5em;
  text-align: left;
}
.imageNtxtUl ul li {
  list-style: none;
  font-family: joc;
  font-size: 3em;
  text-align: left;
}
.imageNtxtUl ul {
  padding: 20px;
}

@media screen and (max-width: 2000px) {
  .imageNtxtUl li {
    font-size: 3em;
  }
  .imageNtxtUl ul li {
    list-style: none;
    font-family: joc;
    font-size: 2em;
    text-align: left;
  }
  #svgManeSLogo {
    height: 40vh;
  }
  #subtitle {
    color: #6d6d6d;
    font-size: 1.5em;
  }
}
@media screen and (max-width: 1381px) {
  .imageNtxtUl {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
  }
  .imageNtxtUl li {
    list-style: none;
    font-family: joc;
    font-size: 1.5em;
    text-align: left;
  }
  .imageNtxtUl ul li {
    list-style: none;
    font-family: joc;
    font-size: 2em;
    text-align: left;
  }

  #svgManeSLogo {
    height: 40vh;
  }

  #subtitle {
    color: #6d6d6d;
    font-size: 1.5em;
  }
}
@media screen and (max-width: 500px) {
  .imageNtxtUl {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    align-items: center;
  }
  .imageNtxtUl li {
    list-style: none;
    font-family: joc;
    font-size: 0px;
    text-align: left;
  }
  .imageNtxtUl ul li {
    list-style: none;
    font-family: joc;
    font-size: 1.5em;
    text-align: left;
  }

  #svgManeSLogo {
    height: 30vh;
  }

  #subtitle {
    color: #6d6d6d;
    font-size: 1.5em;
  }
}

.column {
  display: grid;

  grid-template-columns: repeat(auto-fit, 25rem);

  grid-auto-rows: auto;

  grid-gap: 1rem;
  align-content: center;
  margin: auto;
  justify-content: center;
  width: 100%;

  place-items: center;
}
@media screen and (max-width: 500px) {
  .column {
    grid-template-columns: repeat(auto-fit, 20rem);
  }
}
@media screen and (max-width: 300px) {
  .column {
    grid-template-columns: repeat(auto-fit, 10rem);
  }
}

.ContainSection {
  background-image: linear-gradient(to right, #000000, #2e2e2e);
  content: "";
  display: grid;
  place-items: center;
  clear: both;
  padding: 10px;
  width: 100%;
  min-height: 100vh;
}

.ContainSection ul li {
  float: left;
  max-width: 100px;
  line-height: 80px;
  vertical-align: middle;
  text-align: center;
  margin: 0;
}

.servicesWeProvide {
  margin-top: 50px;
  font-family: joc;
}
.servicesWeProvide h1 {
  font-size: 3em;
  margin: 0px;
  margin-top: 40px;
}

#knowMoreBttn {
  margin-top: 20px;
}
