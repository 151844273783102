.smallCardContainer {
  max-width: 400px;
  min-height: 600px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.123);
  padding: 10px;
  margin: 10px;
  border-radius: 20px;
  transition: 0.3s;
  cursor: pointer;
}
.smallCardContainer:hover {
  box-shadow: none;
}
.cardContent ul {
  font-size: 30px;
  padding: 10px;
}
.cardContent ul li {
  list-style: none;
}
#crdText {
  color: #6d6d6d;
  padding-top: 10px;
}
#smallCardImg {
  width: 8rem;
  transition: 0.3s ease-in;
}

.invertSmallCardImg {
  filter: invert(1);
}
.defaultsmallCardImg {
  filter: invert(0);
}
