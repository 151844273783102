.footerContainer {
  background-image: linear-gradient(to right, #000000, #111111);
  color: white;
  position: relative;
  font-family: joc;
}
.footerContainer .footerBottom {
  background: #2e2e2e;
  color: #6d6d6d;
  height: 50px;
  text-align: center;
  padding-top: 30px;
  width: 100%;
}
.footerContainer .footerContent {
  border: 1px solid #6d6d6d28;
  height: 350px;
  display: flex;
}
.footerContainer .footerContent .footerSection {
  flex: 1;
  border: 1px solid #6d6d6d28;
  padding: 25px;
  text-align: left;
}
#ManeLogo {
  width: 100px;
}
.footerLogo {
  color: #6d6d6d;
}

.footerContainer .footerContent h1,
.footerContainer .footerContent h2 {
  color: white;
}

.footerContainer .footerContent .footerAbout .contact span {
  display: block;
  font-size: 1.1em;
  margin-bottom: 8px;
}

.footerContainer .footerContent .footerAbout .contact {
  margin-top: 20px;
}

.footerContainer .footerContent .footerLinks ul a {
  display: block;
  text-decoration: none;
  margin-bottom: 10px;
  font-size: 1.1em;
  color: #6d6d6d;
  cursor: pointer;
}
.footerContainer .footerContent .footerLinks ul a:hover {
  margin-left: 15px;
  color: white;
  transition: all 0.3s;
}
@media screen and (max-width: 780px) {
  .footerContainer {
    background-image: linear-gradient(to right, #000000, #111111);
    color: white;
    height: auto;
    position: relative;
    font-family: joc;
  }
  .footerContainer .footerBottom {
    background: #2e2e2e;
    color: #6d6d6d;
    height: 50px;
    text-align: center;
    padding-top: 30px;
    width: 100%;
  }
  .footerContainer .footerContent {
    border: 1px solid #6d6d6d28;
    height: auto;
    display: flex;
    flex-direction: column;
  }
}
